footer .content-container{
    padding: 50px 0;
    display: flex;
    justify-content: space-between;
}
.social-icons{
    display: flex;
    gap: 16px;
    margin: 24px 0;
}
footer a{
    color: #fff;
}
.copyright{
    font-family: "Alegreya";
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
}
footer h3{
    color: var(--pink-color);
    font-family: "Alegreya";
    font-weight: 700;
    font-size: 18px;
    margin: 0;
}
footer a:hover{
    text-decoration: underline;
}
footer .links ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
footer .links ul li{
    font-family: "Alegreya";
    font-weight: 400;
    font-size: 16px;
    margin: 8px 16px;
}
.copyright-mobile{
    display: none;
}


  /* smartphone screen */
  
  @media (max-width: 720px) {
    footer .content-container{
        flex-direction: column;
        gap: 40px;
    }
    footer .copyright{
        display: none;
    }
    .copyright-mobile{
        display: block;
        font-family: "Alegreya";
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #fff;
    }
    .social-icons{
        margin-bottom: 0;
    }
    
}