#blog .content-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
}
.post img{
    width: 100%;
    height: 160px;
    object-fit: cover;
}
.post{
    background: #fff;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    transition: all 400ms ease;
}
.post:hover{
    transform: scale(1.04); 
}
.tag{
    position: absolute;
    font-family: "Alegreya";
    font-weight: 700;
    font-size: 13px;
    color: #000;
    background: #fff;
    padding: 0px 16px;
    right: 8px;
    top: 8px;
    border-radius: 10px;
}
.post-title{
    font-family: "Alegreya", serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #000;
    padding: 16px 24px;
    margin: 0;
}
.btn-container{
    display: flex;
    justify-content: right;
}
.all-posts-btn{
    font-family: "Alegreya";
    font-weight: 700;
    font-size: 16px;
    padding: 4px 32px;
    background: #fff;
    border-radius: 10px;
    margin-top: 30px;
    color: #000;
}

/* tablet screen */
@media (max-width: 1040px) {
    #blog .content-container{
        grid-template-columns: 1fr 1fr;
    }
    .post:nth-child(3){
        grid-column: 1/3;
    }
}
  
  
  /* smartphone screen */
  
@media (max-width: 720px) {
    #blog .content-container{
        grid-template-columns: 1fr;
    }
    .post:nth-child(3){
        grid-column: 1/2;
    }
    
}