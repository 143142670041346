#testimonials .content-container{
    margin-top: 50px;
    justify-content: space-between;
}
.testimonials{
    width: 264px;
}
.testimonials img{
    width: 100px;
    height: 100px;
    border-radius: 10px;
}
.name{
    font-family: "Alegreya";
    font-weight: 700;
    font-size: 20px;
    margin-top: 16px;
}
.company-name{
    font-size: 13px;
    text-transform: uppercase;
    padding: 6px 0;
    opacity: 0.7;
}
.review{
    font-family: "Alegreya";
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-top: 4px;
}


/* tablet screen */
@media (max-width: 1040px) {
    #testimonials .content-container{
        flex-direction: column;
        gap: 50px;
        margin-left: 20px;
        margin-top: 50px;
        align-items: center;
    }
    .name{
        margin-top: 0;
    }
    .reviewer-details{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .testimonials{
        display: flex;
        gap: 28px;
        width: 100%;
    }
}
  
  
  /* smartphone screen */
  
@media (max-width: 720px) {
    .testimonials{
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }
    .reviewer-details{
        text-align: center;
    }
}