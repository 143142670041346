#topics .topic-img img{
    width: 550px;
    object-fit: cover;
}
#topics .topics-lists{
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: 24px;
}
#topics .topics-lists li{
    font-family: "Alegreya";
    font-weight: 400;
    font-size: 20px;
    margin: 24px 0;
    position: relative;
}
#topics .topics-lists li::before{
    content: "";
    position: absolute;
    background: var(--red-color);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    left: -24px;
    top: 50%;
    transform: translateY(-50%);
}
#topics .content-container{
    justify-content: space-between;
    align-items: center;
}
#topics .wrapper{
    position: relative;
}
.element-1{
    position: absolute;
    right: -24px;
    bottom: 0;
}



/* tablet screen */
@media (max-width: 1040px) {
    #topics .topic-img img{
        width: 385px;
        height: 280px;
    }
    .element-1{
        right: -24px;
        bottom: 50px;
    }
}
  
  
  /* smartphone screen */
  
@media (max-width: 720px) {
    #topics .topic-img img{
        display: none;
    }
    .element-1{
        right: 32px;
    }
}
