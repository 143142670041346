:root{
  --dark-color : #181E1E;
  --red-color : #E63946;
  --pink-color : #FF6864;
  --yellow-color : #FFCA28;

}
*,*::after,*::before{
  box-sizing: border-box;
}
html{
  scroll-behavior: smooth;
}
body{
  margin: 0;
  font-family: "Inter", sans-serif;
}
.wrapper{
  max-width: 1024px;
  margin: 0 auto;
}
a{
  text-decoration: none;
}

section{
  padding: 80px 0;
  color: #fff;
}
section h2{
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  position: relative;
}
section h2::after{
  content: "";
  position: absolute;
  width: 80px;
  height: 4px;
  background: var(--red-color);
  left: 0;
  bottom: -10px;
}
section h2.light::after{
  background: var(--yellow-color);
}
section .content-container{
  display: flex;
}
.black{
  background-color: #000;
}
.dark-gray{
  background: var(--dark-color);
}
.pink{
  background: var(--pink-color);
}
::-webkit-scrollbar{
  display: none;
}

/* tablet screen */
@media (max-width: 1040px) {
  .wrapper{
    max-width: 694px;
  }
}


/* smartphone screen */

@media (max-width: 720px) {
  .wrapper{
    padding: 0 32px;
  }
  section h2{
    font-size: 24px;
   }
   section h2::after{
    width: 50px;
    bottom: 0;
   }
}