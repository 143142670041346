.info-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 4px;
    margin-top: 60px;
}
.info-content .amount{
    font-weight: 900;
    font-size: 40px;
}
.info-content .type{
    font-family: "Alegreya",serif;
    font-weight: 700;
    font-size: 30px;
}
.wrapper{
    position: relative;
}
.element-2{
    position: absolute;
    top: 0;
    left: 0;
}
  
  
  /* smartphone screen */
  
@media (max-width: 720px) {
    #info .content-container{
        flex-direction: column;
        gap: 80px;
    }
    .info-content img{
        height: 100px;
    }
    .info-content .amount{
        font-size: 32px;
    }
    .info-content .type{
        font-size: 24px;
    }
    .element-2{
        left: 32px;
    }
}
