header{
    min-height: 100vh;
    background: url('../../img/Rectangle\ 8.png');
    background-size: cover;
    background-position: center;
}
header .wrapper{
    position: relative;
    height: 100vh;
}
header .cta{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 450px;
    background-color: rgba(0, 0, 0, 0.5);
    border-width: 4px 4px 0px 4px;
    border-style: solid;
    border-color: #fff;
    backdrop-filter: blur(25px);
    border-radius: 20px 20px 0 0;
    padding: 50px 40px;
}
.course-name{
    font-weight: 800;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    position: relative;
}
.course-name::after{
    content: "";
    position: absolute;
    width: 60px;
    height: 4px;
    background: var(--red-color);
    bottom: -10px;
    left: 0;
}
header .cta h1{
    font-family: 'Alegreya',sans-serif;
    font-size: 40px;
    color: #fff;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}
.demo-btn{
    padding: 10px 32px;
    border-radius: 10px;
    background: var(--red-color);
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
}



/* tablet screen */
@media (max-width: 1040px) {
    header .cta{
        width: 356px;
    }
}
  
  
  /* smartphone screen */
  
@media (max-width: 720px) {
    header .cta{
        width: unset;
        right: 32px;
        left: 32px;
    }
    header .cta h1{
        font-size: 32px;
    }
}